
import { AddUserModalDto, userTypes } from "@/api/interfaces/users/users.dto";
import { useUserTable } from "@/hooks/tables/modules/usersTable/usersTable";
import { sortOrders } from "@/enums/main/sortOrders";
import { IObjectKeys } from "@/interfaces/IObjectKeys";
import { accountsSetup } from "@/views/interfaces/accountsInterfaces";
import { useStore } from "vuex";
import { watch, computed, defineComponent, reactive } from "vue";
import { pageFilter } from "@/components/Table/ts/hooks/useAppTable";
import { accountFilterStatuses } from "@/enums/accounts/accountFilterStatuses";
import {
  IUseBaseAppLayout,
  useBaseAppLayout
} from "@/hooks/layout/useBaseAppLayout";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";

import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import AccountsTable, {
  accountsTableSort
} from "@/components/Table/ts/instances/accounts/AccountsTable.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import CreateUserDialog from "@/components/ui/Modal/Dialog/instances/CreateUserDialog.vue";
import DeleteUserDialog from "@/components/ui/Modal/Dialog/instances/DeleteUserDialog.vue";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { useUsers } from "@/hooks/users/useUsers";
import { createUser } from "@/hooks/users/modules/createUser";

const AccountsSortKeys = {
  name: "name",
  registerCountry: "registerCountry",
  balance: "balance"
};

export const accountsSortKeys: IObjectKeys = [
  AccountsSortKeys.name,
  AccountsSortKeys.registerCountry,
  AccountsSortKeys.balance
];

const filters = [
  accountFilterStatuses.recentlyCreated,
  accountFilterStatuses.fullName,
  accountFilterStatuses.country
];

export default defineComponent({
  name: "Accounts",
  components: {
    AppTableController,
    DeleteUserDialog,

    CreateUserDialog,
    BtnContent,
    AppButton,
    SelectDropdown,
    ShadowControl,
    PageMainHeading,
    AccountsTable
  },

  setup(): accountsSetup {
    const store = useStore();

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });

    // Page layout refs
    const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
      initialSearchQuery: "",
      filterOptions: filters,
      onFiltersChange: () => requestAccountsHandler(true, fullFilter.value),
      onChangeSearchQuery: () => {
        changeViewConfigurationHandler({
          page: 1,
          perPage: viewConfiguration.perPage
        });
      }
    });

    watch(viewConfiguration, () => requestAccountsHandler());

    const { filter, searchQuery } = layoutRefs;
    const selectedFilterName = computed((): string => {
      if (!filter) return "";
      return (
        filter.find(({ selected }: pageFilter) => selected)?.name ||
        accountFilterStatuses.recentlyCreated
      );
    });
    const fullFilter = computed(() => {
      return {
        text: searchQuery.value,
        sortUser: selectedFilterName.value,
        type: userTypes.user,
        sortSpec: "",
        filterList: {}
      };
    });
    const options = {
      viewConfiguration,
      filter: fullFilter.value
    };
    const { changeViewConfigurationHandler, requestAccountsHandler } = useUsers(
      options
    );
    requestAccountsHandler();
    const createUserHandler = async (data: any) => {
      return await createUser(data, store);
    };
    return {
      ...layoutRefs,
      ...useUserTable(),
      viewConfiguration,
      searchQuery,
      accountsList: computed(() => store.getters.accountsList),
      totalAccountsCount: computed(() => store.getters.totalAccounts),
      requestAccountsHandler,
      createUserHandler,
      changeViewConfigurationHandler
    };
  },

  data() {
    return {
      sortConfig: {
        byName: { order: sortOrders.turnOff, keyName: "fullName" },
        byLoginCountry: { order: sortOrders.turnOff, keyName: "loginCountry" },
        byBalance: { order: sortOrders.turnOff, keyName: "balance" }
      },
      isLoading: false
    } as {
      sortConfig: accountsTableSort;
      isLoading: boolean;
    };
  },

  computed: {
    emptyText(): string {
      if (!this.searchQuery) {
        return "There are no accounts created yet!";
      }

      return `No users found by request "${this.searchQuery}"`;
    }
  },

  methods: {
    async userToggleBlockHandler(id: number) {
      const currentBlocked = this.accountsList.find(
        (account: IUserAccount) => account.id === id
      )?.blocked;

      const { success, message } = await this.$store.dispatch("setBlocked", {
        id,
        param: !currentBlocked
      });

      if (success) {
        await this.requestAccountsHandler?.(false);
        return;
      }

      message && this.$store.dispatch("showErrorNotification", message);
    },

    async onUserCreateSubmit(data: AddUserModalDto): Promise<boolean> {
      this.isLoading = true;
      const result = await this.createUserHandler({
        ...data,
        type: userTypes.user
      });
      this.isLoading = false;
      if (result) {
        this.hideHandler();
      }
      return result;
    },

    onDeleteConfirm() {
      this.deleteUser(() => {
        this.requestAccountsHandler?.(false);
      });
    },
    hideHandler() {
      this.createUserDialogOpened = false;
    }
  }
});
